import React from 'react'
import Stats from '../Stats'

function Profile() {
  return (
    <>
    <Stats />
    </>
  )
}

export default Profile