import React from 'react'
import Card from '../Card'

function Info() {
  return (
    <>
    <Card />
    </>
  )
}

export default Info